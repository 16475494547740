export var task = [{
	text: "Weekly Bigbazar Shopping",
	completed: false
},
{
	text: "Go Outside Picnic on Sunday",
	completed: false
},
{
	text: "Write a blog post",
	completed: true
},
{
	text: "Do the chicken dance",
	completed: true
},
{
	text: "Pay the electricity bills",
	completed: false
}]